<template>
  <div class="test-result">
    <div class="back-btns">
      <div
        v-if="!viewMode"
        @click="backToList"
        class="btn-bg mt-2">Back</div>
    </div>
    <div class="test-title">
      Result
      <div class="date">
        {{result.testDate}}
      </div>
    </div>
    <div class="summary">
      <div class="text">
        <h2>{{result.testName}}</h2>
        <div>
          <h2>Points： {{result.score}}/{{result.totalScore}}</h2>
          <h2 class="ml-4">{{result.grade}} 名前：{{result.username}}</h2>
        </div>
        <div>
          <h2>Correct： {{result.rightAnswerNumber}}</h2>
          <h2 class="ml-4">Incorrect： {{result.wrongAnswerNumber}}</h2>
          <h2 class="ml-4">Skip： {{result.unknownAnswerNumber}}</h2>
        </div>
        <div>
          <h2>Details</h2>
        </div>
      </div>
      <div class="answer">
        <div class="percentage">{{$filters.percentage(correctRate)}}%</div>
        <div>
          <a :href="result.descriptionUrl" target="_blank">
            <img src="@/assets/icon-description.png" />
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="result.partResults"
      >
      <table
        v-if="result.step==1"
        class="result-table">
        <tr>
          <td></td>
          <td>Part</td>
          <td>Unit</td>
          <td>Lesson</td>
          <td>Content</td>
          <td>Points</td>
        </tr>
        <tr>
          <td rowspan="8">Reading</td>
          <td rowspan="4">1</td>
          <td rowspan="2">1</td>
          <td>Lesson1</td>
          <td>{{lessonTitle(1)}}</td>
          <td rowspan="4">{{score(1, 1)}}</td>
        </tr>
        <tr>
          <td>Lesson2</td>
          <td>{{lessonTitle(2)}}</td>
        </tr>
        <tr>
          <td rowspan="2">2</td>
          <td>Lesson3</td>
          <td>{{lessonTitle(3)}}</td>
        </tr>
        <tr>
          <td>Lesson4</td>
          <td>{{lessonTitle(4)}}</td>
        </tr>
        <tr>
          <td rowspan="2">2</td>
          <td rowspan="2">3</td>
          <td>Lesson5</td>
          <td>{{lessonTitle(5)}}</td>
          <td rowspan="2">{{score(1, 2)}}</td>
        </tr>
        <tr>
          <td>Lesson6</td>
          <td>{{lessonTitle(6)}}</td>
        </tr>
        <tr>
          <td rowspan="2">3</td>
          <td>4</td>
          <td>Lesson7</td>
          <td>{{lessonTitle(7)}}</td>
          <td rowspan="2">{{score(1, 3)}}</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Lesson8</td>
          <td>{{lessonTitle(8)}}</td>
        </tr>
        <tr>
          <td rowspan="8">Listening</td>
          <td>1</td>
          <td>6</td>
          <td>Lesson9</td>
          <td>{{lessonTitle(9)}}</td>
          <td>{{score(2, 1)}}</td>
        </tr>
        <tr>
          <td>2</td>
          <td>7</td>
          <td>Lesson10</td>
          <td>{{lessonTitle(10)}}</td>
          <td>{{score(2, 2)}}</td>
        </tr>
        <tr>
          <td rowspan="2">3</td>
          <td rowspan="2">8</td>
          <td>Lesson11</td>
          <td>{{lessonTitle(11)}}</td>
          <td rowspan="2">{{score(2, 3)}}</td>
        </tr>
        <tr>
          <td>Lesson12</td>
          <td>{{lessonTitle(12)}}</td>
        </tr>
        <tr>
          <td rowspan="2">4</td>
          <td rowspan="2">9</td>
          <td>Lesson13</td>
          <td>{{lessonTitle(13)}}</td>
          <td rowspan="2">{{score(2, 4)}}</td>
        </tr>
        <tr>
          <td>Lesson14</td>
          <td>{{lessonTitle(14)}}</td>
        </tr>
        <tr>
          <td rowspan="2">5</td>
          <td rowspan="2">10</td>
          <td>Lesson15</td>
          <td>{{lessonTitle(15)}}</td>
          <td rowspan="2">{{score(2, 5)}}</td>
        </tr>
        <tr>
          <td>Lesson16</td>
          <td>{{lessonTitle(16)}}</td>
        </tr>
      </table>
      <table
        v-if="result.step==2"
        class="result-table">
        <tr>
          <td></td>
          <td>Part</td>
          <td>Unit</td>
          <td>Lesson</td>
          <td>Content</td>
          <td>Points</td>
        </tr>
        <tr>
          <td rowspan="8">Reading</td>
          <td rowspan="2">1</td>
          <td rowspan="2">1</td>
          <td>Lesson1</td>
          <td>{{lessonTitle(1)}}</td>
          <td rowspan="2">{{score(1, 1)}}</td>
        </tr>
        <tr>
          <td>Lesson2</td>
          <td>{{lessonTitle(2)}}</td>
        </tr>
        <tr>
          <td rowspan="6">2</td>
          <td>2</td>
          <td>Lesson3</td>
          <td>{{lessonTitle(3)}}</td>
          <td rowspan="6">{{score(1, 2)}}</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Lesson4</td>
          <td>{{lessonTitle(4)}}</td>
        </tr>
        <tr>
          <td rowspan="4">4</td>
          <td>Lesson5</td>
          <td>{{lessonTitle(5)}}</td>
        </tr>
        <tr>
          <td>Lesson6</td>
          <td>{{lessonTitle(6)}}</td>
        </tr>
        <tr>
          <td>Lesson7</td>
          <td>{{lessonTitle(7)}}</td>
        </tr>
        <tr>
          <td>Lesson8</td>
          <td>{{lessonTitle(8)}}</td>
        </tr>
        <tr>
          <td rowspan="8">Listening</td>
          <td>1</td>
          <td>5</td>
          <td>Lesson9</td>
          <td>{{lessonTitle(9)}}</td>
          <td>{{score(2, 1)}}</td>
        </tr>
        <tr>
          <td rowspan="2">2</td>
          <td rowspan="2">6</td>
          <td>Lesson10</td>
          <td>{{lessonTitle(10)}}</td>
          <td rowspan="2">{{score(2, 2)}}</td>
        </tr>
        <tr>
          <td>Lesson11</td>
          <td>{{lessonTitle(11)}}</td>
        </tr>        
        <tr>
          <td rowspan="2">3</td>
          <td rowspan="2">7</td>
          <td>Lesson12</td>
          <td>{{lessonTitle(12)}}</td>
          <td rowspan="2">{{score(2, 3)}}</td>
        </tr>
        <tr>
          <td>Lesson13</td>
          <td>{{lessonTitle(13)}}</td>
        </tr>
        <tr>
          <td rowspan="3">4</td>
          <td rowspan="3">8</td>
          <td>Lesson14</td>
          <td>{{lessonTitle(14)}}</td>
          <td rowspan="3">{{score(2, 4)}}</td>
        </tr>
        <tr>
          <td>Lesson15</td>
          <td>{{lessonTitle(15)}}</td>
        </tr>
        <tr>
          <td>Lesson16</td>
          <td>{{lessonTitle(16)}}</td>
        </tr>
      </table>
    </div>
    <div class="align-left"><h2>Advice</h2></div>
    <div
      v-html="result.evaluation"
      class="align-left border font-size-20"></div>
    <div
      class="align-left"><h2>Study Curriculum</h2></div>
    <div
      class="border flex align-items-center mb-2">
      <div class="flex-1 align-left">
        <div class="textbook">
          <span>{{result.recommendAppName}}</span>
        </div>
      </div>
      <div v-if="!viewMode">
        <div v-if="result.hasRecommendApp"
          @click="recommendApp"
          class="start-btn ml-2">
          Start
        </div>
        <div v-else class="flex">
          <div
            @click="freeApp"
            class="start-btn">
            Free Trial
          </div>
          <div
            @click="recommendApp"
            class="start-btn ml-2">
            Buy license
          </div>
        </div>
      </div>
      <div
        v-if="viewMode"
        @click="print"
        class="start-btn mt-1 mb-2">
        Print
      </div>
    </div>
    <div
        v-if="!viewMode"
        @click="print"
        class="btn-bg mt-1 mb-2">Print</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: { },
  name: 'TrialTestResult',
  data() {
    return {
      viewMode: false,
      result: {},
    }
  },
  computed: {
    ...mapState(['auth',]),
    prontestSeriesUrl() {
      return process.env.VUE_APP_SERIES_URL
    },
    correctRate()  {
      const t = this.result
      return t.rightAnswerNumber / (t.rightAnswerNumber + t.wrongAnswerNumber + t.unknownAnswerNumber)
    },
    listeningParts() {
      return this.result.partResults.filter(p => p.type === 1)
    },
    readingParts() {
      return this.result.partResults.filter(p => p.type === 2)
    },
    score() {
      return (type, part) => {
        const result = this.result?.partResults.find(p => p.type === type && p.part === part)
        if (result) {
          return `${result.score}/${result.totalScore}`
        } else {
          return '******'
        }
      }
    },
    lessonTitle() {
      return id => this.result?.lessonTitles.find(t => t.id === id)?.title
    }
  },
  created() {
    const testHistoryId = this.$route.params.testHistoryId
    const encryptTestHistoryId = this.$route.query.id
    const mode = this.$route.query.mode
    if (mode === 'view') this.viewMode = true
    if (testHistoryId) {
      this.$api.get(`/testResult/trial/${testHistoryId}`).then(res => {
        this.result = res.data
      })
    } else if (encryptTestHistoryId) {
      this.viewMode = true
      this.$api.get(`/testResult/noAuth/trial?id=${encodeURIComponent(encryptTestHistoryId)}`).then(res => {
        this.result = res.data
      })
    }
  },
  methods: {
    backToList() {
      // this.$router.push('/lessonResults?type=trial')
      this.$router.push('/menu')      
    },
    startExam(id) {
      this.$router.push(`/lesson/${id}/exam`)
    },
    showResults() {
      console.log('startDate:' + this.startDate)
      console.log('endDate:' + this.endDate)
    },
    setStartDate(v) {
      this.startDate = v
    },
    setEndDate(v) {
      this.endDate = v
    },
    print() {
      window.print()
    },
    freeApp() {
      const pageUrl = encodeURIComponent(`purchase.html?id=${this.result.recommendFreeAppId}`)
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=${pageUrl}`
    },
    recommendApp() {
      const pageUrl = encodeURIComponent(`purchase.html?id=${this.result.recommendAppId}`)
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=${pageUrl}`
    },
  }
}
</script>

<style lang="scss" scoped>
@media print {
  .btn-bg {
    display: none;
  }
}
.test-result {
  background: white;
  .back-btns {
    text-align: right;
  }
  h2 {
    margin: 5px 0;
    display: inline-block;
    font-size: 30px;
  }
  .ml-4 {
    margin-left: 30px;
  }
  .summary {
    display: flex;
    font-size: 25px;
    .text {
      flex: 5;
      text-align: left;
    }
    .answer {
      margin: 15px;
      margin-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
      .percentage {
        font-size: 49px;
      }
      img {
        height: 60px;
      }
    }
  }
  .border {
    border: 1px solid rgb(189, 183, 183);
    padding: 10px;
  }
  .flex-1 {
    flex: 1;
  }
  .align-items-center {
    align-items: center;
  }
  .start-btn {
    padding: 10px 30px;
    border: 1px solid rgb(189, 183, 183);
    border-radius: 30px;
    cursor: pointer;
    font-size: 22px;
    cursor: pointer;
  }
  .test-title {
    text-align: center;
    font-size: 38px;
    .date {
      position: relative;
      margin-top: -40px;
      font-size: 28px;
      text-align: right;
    }
  }
  .result-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(189, 183, 183);
    font-size: 20px;
    color: #565258;
    font-weight: bold;
    td {
      border: 1px solid #565258;
      padding: 2px;
    }
  }
  .font-size-20 {
    font-size: 20px;
    color: #707070;
  }
  .textbook {
    font-size: 25px;
    color: #707070;
  }
}
</style>

